import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'

function contact() {
    return (
        <>
          <Helmet title="Contact | MM-develops"/>
          <Layout>
              <section>
                <h1>CONTACT ME</h1>
                <h5>Have a project you'd like to collaborate on?<br/>Need a front end developer for your business?<br/>Want a new friend? Send me an email!</h5>
                <a href="mailto:monicameraz98@gmail.com">monicameraz98@gmail.com</a><br/>
                <a href="tel:+16195138314">(619) 5138314</a><br/>
                <a href="https://www.linkedin.com/in/monica-meraz/">Linkedin</a><br/>
                <a href="https://github.com/MM-develops">GitHub</a>
              </section>
          </Layout>  
        </>
    )
}

export default contact
